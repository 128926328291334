global.$ = require('jquery');
global.jQuery = require('jquery');

const ObjectFitImages = require('object-fit-images');
const Swiper = require('swiper');

const marquee = require('jquery.marquee');


var mobile_w = 767;
ObjectFitImages();


// Menu navigation ---------------------------------------------------------------

var $nav = $(".primary-nav");

$("#menu-button").click(function(e){
  e.preventDefault();

  if($("#menu-toggle").is(':checked')){
    $nav.removeClass("nav-active");
    $("#menu-toggle").prop( "checked", false );
  }
  else{
    $("#menu-toggle").prop( "checked", true );
    $nav.addClass("nav-active");

  }
});
$(".primary-nav a").click(function(e){
  $("#menu-toggle").prop( "checked", false );
  $nav.removeClass("nav-active");
});


// Hero marquee ---------------------------------------------------------------

$('#marquee').marquee({
  duplicated: true,
  startVisible: true,
  gap: 0,
  speed: 100
});


// Lightbox ---------------------------------------------------------------
var $lightbox_trigger = $("[data-lightbox]"),
$lightbox_bg = $(".lightbox-bg, .btn-close"),
lightbox_id;

$lightbox_trigger.click(function(e){
  e.preventDefault();
  $("body").addClass("lightbox-open");
  lightbox_id = $(this).data("lightbox");
  $("[data-lightbox-id='"+ lightbox_id + "']").addClass("active");
  $(".lightbox-bg").addClass("active");
  $(".btn-close").addClass("active");
});
$lightbox_bg.click(function(e){
  close_lightbox();
});

$(document).keyup(function(e) {
   if (e.key === "Escape") { // escape key maps to keycode `27`
      close_lightbox();
  }
});

function close_lightbox(){
  $("body").removeClass("lightbox-open");
  $("[data-lightbox-id]").removeClass("active");
  $(".lightbox-bg").removeClass("active");
  $(".btn-close").removeClass("active");
}

// Lines animation ---------------------------------------------------------------

var $this_container = $(".lines-intro"),
    $lines = $this_container.find(".line");

var i = 0,
    selected_lines = [40, 140, 180];

var timer = setInterval(function(){
  console.log(i);

  if (i > selected_lines.length-1 ){
    clearInterval(timer);
  }
  else{
    var $this_line = $lines.eq(selected_lines[i]);
    $this_line.remove().insertAfter($this_container.find(".line:last"));
    i++;
    setTimeout(function(){
      $this_line.addClass("full-line big-line-" + i);
    },10);
  }

},1000);



// Accordions---------------------------------------------------------------
var $accordion_trigger = $("[data-accordion]"),
  accordion_id,
  accordion_status;

$accordion_trigger.click(function(e){
  e.preventDefault();
  accordion_id = $(this).data("accordion");
  var $this_accordion = $("#"+ accordion_id);
  $(this).toggleClass("has-accordion-open");
  $( $this_accordion ).toggleClass("accordion-open");
  if ($this_accordion.hasClass("accordion-open") ) {
    //
  }
  else {
    //
  }

});
